import React, {useState} from 'react';
import Layout from "../Layout";
import Wrapper from "../../atoms/Wrapper";
import Container from "../../atoms/Container";
import {GatsbyImage} from "gatsby-plugin-image";
import Tag from "../../atoms/Tag";
import Text from "../../atoms/Text";
import {graphql, Link, PageProps} from "gatsby";
import SubscribeMailist from "../SubscribeMailist";
import EnjoyingRead from "../EnjoyingRead";
import BlogCard from "../BlogCard";
import {PortableText, PortableTextBlockComponent} from '@portabletext/react'
import {BlogSEO} from "../../atoms/SEO";

const COMPONENTS:{block:Record<string, PortableTextBlockComponent>} = {
    block:{
        blockquote:({children}:any)=>{
            if(typeof children?.at(0)==='string'){
                if(children?.at(0)?.includes('[image]')){
                    const imageURL = children?.at(0)?.split('[image]')[1];
                    if(imageURL){
                        return <img src={imageURL} alt={'Image'}/>
                    }
                }
                if(children?.at(0)?.includes('[description]')){
                    return null;
                }
            }
            return <blockquote className={'text-[#323b4b]'}>{children}</blockquote>;
        },
        h2:({children})=><h2 className={'h2 text-[#323b4b]'}>{children}</h2>,
        h3:({children})=><h3 className={'h2 text-[#323b4b]'}>{children}</h3>,
        h4:({children})=><h4 className={'h3 text-[#323b4b]'}>{children}</h4>,
        normal:({children})=> {
            if(Array.isArray(children)&&children.some(child=>child?.props?.text?.includes('[link]'))){
                return <p className={'text-[#4e5d78e] text-justify inter-400-14-20 !leading-[28px] '}>
                    {children.map(child=>{
                        if(child?.props?.text?.includes('[link]')){
                            const link2=child?.props?.text?.split('[link]')[1];
                            const [src,text]=link2.split('<=>')
                            return <a target={'_blank'} rel={'noopener'} className={' inter-400-14-20 !leading-[28px]'} href={src}>{text}</a>
                        }
                        return <>{child}</>
                    })}
                </p>
            }
            return <p className={'text-[#4e5d78e] text-justify text-[17px] leading-[28px] '}>{children}</p>
        },

    }
}

export default function BlogPost({data,pageContext}:PageProps<Queries.BlogPostQuery>){

    const [showWaitListForm, setShowWaitListForm] = useState(false); //to keep track of visibility of modal

    function displayWaitListForm() {
        //display the wait-list modal/form by setting showWaitListFrom to true
        setShowWaitListForm(true);
    }

    function hideDisplayWaitListForm() {
        //hide the wait-list modal/form by setting showWaitListFrom to false
        setShowWaitListForm(false);
    }
    const {slug}=pageContext as {slug:string};
    let blog=data.allSanityPost.nodes.find((b)=>b.slug?.current===slug);
    if(!blog) return null;

    let relatedArticles:any[]=[];
    data.allSanityPost.nodes.slice(0,4).forEach((b)=>{
        if(b.slug?.current!==slug) relatedArticles.push(b)
    })
    relatedArticles=relatedArticles.slice(0,3);
    const compiledImage=blog.mainImage?.asset?.gatsbyImageData;
    const compiledAuthorImage=blog.author?.image?.asset?.gatsbyImageData;
    let date="Few days back";
    if(blog.publishedAt) date=new Date(blog.publishedAt).toDateString();
    const blockquote = blog.body?.find(block=>block?.style==='blockquote'&&block.children?.at(0)?.text?.includes('[description]'))
    let description=blockquote?.children?.at(0)?.text?.split('[description]')[1]||"A SaaSPay Blog";
    return <Layout displayWaitListForm={displayWaitListForm} hideDisplayWaitListForm={hideDisplayWaitListForm} showWaitListForm={showWaitListForm}>
        <BlogSEO title={blog.title} summary={description} authorDescription={blog.author?.name} date={blog.publishedAt} lastmod={blog.publishedAt}/>
        <Wrapper className={''}>

            <Container className={"!max-w-[750px] "}>
                <Link to={'/'} className="text-p1 flex items-center my-3 md:my-8">
                    <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.89941 1L5.36388 4.53553L1.82835 8.07107L8.89941 15.1421" stroke="#5B2EBC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <Text text={'Back to Blog'} textStyle={'inter-400-14-20'} className={'!font-semibold ml-5 text-p1 mb-[2px]'}/>

                </Link>
                <div className="mb-3 md:mb-6">
                    {compiledImage&&<GatsbyImage className={'w-full'} alt={blog.title||'Blog Feature Image'} image={compiledImage}/>}
                </div>
                <div className="flex w-full mb-3 md:mb-6 gap-x-2">
                    {blog.categories?.map((c:any)=><Tag key={c.title} color={'#9D43A5'} text={c.title}/>)}
                </div>
                <div className="mb-3 md:mb-[25px]">
                    <h1 className={'sr-only'}>{blog.title}</h1>
                    {blog.title&&<Text textStyle={'h1'} text={blog.title}/>}
                </div>
                <div className={'flex'}>
                    {compiledAuthorImage&&<span className={'flex items-center bg-p2 w-[60px] h-[60px] rounded-full overflow-hidden'}><GatsbyImage alt={blog.author?.name||"Author"} image={compiledAuthorImage}/></span>}
                    {!compiledAuthorImage&&<div className={'w-[60px] h-[60px] rounded-full overflow-hidden bg-p1'}/>}
                    <div className="ml-3 md:ml-8">
                        <div className="">
                            <Text text={blog.author?.name||"TEAM SAASPAY"} textStyle={'inter-600-16-29'} className={''}/>
                        </div>
                        <div className="">
                            <Text text={date} textStyle={'inter-600-16-29'} className={'!text-[#979797]'}/>
                        </div>
                    </div>
                </div>
                <div className={'bg-[#F4F4F4] h-0.5 w-full my-6 md:my-12'}/>
                <div className={'prose max-w-none '}>

                    <PortableText
                        components={COMPONENTS}
                        value={blog.body as any[]||[]}
                    />
                </div>
                <div className={'bg-[#F4F4F4] h-0.5 w-full my-12'}/>
                <EnjoyingRead/>
                {relatedArticles.length>0&&<div>
                    <div className={'mb-[50px]'}>
                        <Text text={'Related Articles'} textStyle={'h3 !text-[#183B56]'}/>
                    </div>
                    <div className={'flex flex-wrap md:flex-nowrap gap-x-[73px] gap-y-[50px] mb-[100px]'}>
                        {relatedArticles.map(b=><BlogCard blog={b}/>)}
                    </div>
                </div>}
            </Container>
        </Wrapper>
        <SubscribeMailist/>

    </Layout>
}
export const query=graphql`
    query BlogPost{
    allSanityPost {
    nodes {
      title
      slug {
        current
      }
      mainImage {
        asset {
          gatsbyImageData
        }
      }
      author {
        name
        bio {
        _type
        list
        style
        _key
        children {
          _type
          _key
          marks
          text
        }
        }
        image {
          asset {
            gatsbyImageData
          }
        }
      }
      publishedAt
      body {
        _rawChildren
        _type
        list
        style
        _key
        children {
          _type
          _key
          marks
          text
        }
      }
      categories {
        title
      }
    }
  }
}
  `

import React from 'react';
import Text from "../../atoms/Text";
import SubscribeFormInput from "../../atoms/SubscribeFormInput";

type PROPS_STYLE={

}

export default function EnjoyingRead(props:PROPS_STYLE){
    return <div className={'bg-[#f4f4f4] rounded-[8px] p-[30px] mb-[50px]'}>
        <div className="mb-5">
            <Text text={'Enjoying this read?'} textStyle={'h2 !font-semibold'}/>
        </div>
        <div className="mb-[30px] max-w-[654px]">
            <Text text={'Stay up to date with the latest video business news, strategies, and insights sent straight to you inbox!'} textStyle={'open-400-16-28'} className={'!text-[#5A7184]'}/>
        </div>
        <SubscribeFormInput variant={'secondary'} rounded={false}/>
    </div>
}
